import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { EnvironmentData } from '../../../shared/models/environment.data.model';
import { DrugCodeInfo } from '../../jcard-page/models/drug-code-info.data.model';
import {
    DepartmentData,
    DesignationData, Feedback,
    ResponseData
} from '../models';
import { GlobalNotesOutPut } from '../models/global-notes.data.model';
import { GlobalSearchViews } from '../models/global-search-views.data.model';
@Injectable()
export class LandingPageService {
  public headers: HttpHeaders = null;
  public config:EnvironmentData;
  constructor(private _http: HttpClient) { }

  private setHeaders():HttpHeaders{
    this.config = JSON.parse(sessionStorage.getItem('config'));
    let apiKey = sessionStorage.getItem('ak')
    return this.headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('x-api-key', apiKey)
      .set('Authorization', sessionStorage.getItem('uid')?sessionStorage.getItem('uid'):this.config.Authorization);
  }

  public getWelcomeScreen(fileName: string) : Observable<string>{
    return this._http.get('./assets/' + fileName, {responseType: 'text'});
  }

  public tandCScreen() : Observable<string>{
    return this._http.get('../termsandConditions.html', {responseType: 'text'});
  }

  public getWelcomePageFromS3(fileName:string): Observable<string> {
    let config = (JSON.parse(sessionStorage.getItem('config'))) as EnvironmentData;
    return this._http.get(config.HtmlBasePath + fileName, { responseType: 'text' });
  }

  public getSurveyPageFromS3(): Observable<string> {
    let config = (JSON.parse(sessionStorage['config'])) as EnvironmentData;
    return this._http.get(config.HtmlBasePath + 'survey.html', { responseType: 'text' });
  }

  public getSurveyQuestions(accountId:number,surveyId:number):Observable<GlobalSearchViews>{
    let headers =  this.setHeaders();
    return this._http.get(this.config.ServiceUrl + '/surveys/'+surveyId,{headers:headers});
  }

  public saveHistory(jsonData: object): Promise<object> {
    let headers = this.setHeaders();
    return this._http.post(this.config.ServiceUrl + '/user_history/add_history', jsonData, { headers }).toPromise();
}

  public saveFeedback_old(jsonData: Feedback): Observable<string> {
    let config = (JSON.parse(sessionStorage['config'])) as EnvironmentData;
    return this._http.post<string>(config.LocalServiceUrl + 'rest/send/notification', jsonData);
  }

  public saveFeedback(jsonData: Feedback): Observable<string> {
    let headers =  this.setHeaders();
    return this._http.post<string>(this.config.ServiceUrl + '/email/sendFeedback', jsonData, { headers });
  }

  public saveKycDetails(jsonData:object): Observable<ResponseData> {
    let headers =  this.setHeaders();
    return this._http.post(this.config.ServiceUrl + '/account/updateAccount',	jsonData,{headers});
  }

  public termAAID(jsonData:object): Observable<ResponseData> {
    let headers =  this.setHeaders();
    return this._http.post(this.config.ServiceUrl + '/account_activities/termActivity',	jsonData,{headers});
  }
  public getHtmlFromS3(pageName:string): Observable<string> {
    let config = (JSON.parse(sessionStorage.getItem('config'))) as EnvironmentData;
    return this._http.get(config.HtmlBasePath + pageName, { responseType: 'text' });
  }
  public persistTermsNConditions (jsonData:object): Observable<ResponseData> {
    let headers =  this.setHeaders();
    return this._http.post(this.config.ServiceUrl+'/account_activities/addActivity',	jsonData,{headers});
  }
  public tcActivity (jsonData:object): Observable<ResponseData> {
    let headers =  this.setHeaders();
    return this._http.post(this.config.ServiceUrl+'/account_activities/tcActivity',	jsonData,{headers});
  }
  public termUserExperienceSurveyPage (jsonData:object): Observable<ResponseData> {
    let headers =  this.setHeaders();
    return this._http.post(this.config.ServiceUrl+'/account_activities/termActivity',	jsonData,{headers});
  }
  public getTermAndConditionPdfData () {
    let headers = this.setHeaders();
    return this._http.get(this.config.ServiceRootUrl + '/files/downloadFile/TermsAndConditions.pdf', { headers: headers });
  }
  public getDrugCodes(accountId:number,searchText:string,searchType:string,searchId:number){
    let headers =  this.setHeaders();
    return this._http.get(this.config.ServiceUrl + '/gsv/'+searchType+'/'+searchId+'/'+'0/'+'1000/'+searchText,{headers:headers, observe: 'response' });
  }
  public getDrugForFilterSearch(accountId: number, searchText: string,
    searchType: string, searchId: number, offset: number, limit: number): Observable<Array<GlobalSearchViews>> {
    let headers = this.setHeaders();
    return this._http.get(this.config.ServiceUrl + '/gsv/' + searchType + '/' + searchId + '/'
      + offset + '/' + limit + '/' + searchText, { headers: headers }) as Observable<Array<GlobalSearchViews>>;
  }
  public getProductUpdates(id:number,userAccount:number): Observable<string> {
    let config = (JSON.parse(sessionStorage.getItem('config'))) as EnvironmentData;
    let headers = this.setHeaders();
    return this._http.get(config.ServiceUrl + '/RJServ/servlet/Notif?id='+id+'&user='+userAccount,{headers: headers, responseType: 'text' });
  }
  public getGlobalAlertData(searchType:string):Observable<GlobalNotesOutPut>{
    let headers =  this.setHeaders();
    return this._http.get(this.config.ServiceUrl + '/global_notes/'+searchType,{headers:headers});
  }

  public addRecentSearche(accountId: number, rcId: string, searchType: string, searchTexts: string): Observable<DrugCodeInfo> {
    let headers = this.setHeaders();
    return this._http.get(this.config.ServiceUrl + '/selectedcode/' + rcId + '/' + searchType + '/' + searchTexts,
        { headers: headers });
}

public getDepartmentList():Observable<DepartmentData>{
  return this._http.get('./assets/config/department-list.json');
}

public getDesignationList():Observable<DesignationData>{
  return this._http.get('./assets/config/designation-list.json');
}

  public getDrugForFilterSearchPagination(accountId: number, searchText: string,
    searchType: string, searchId: number, offset: number, limit: number, conditions: string): Observable<Array<GlobalSearchViews>> {
    let headers = this.setHeaders();
    return this._http.get(this.config.ServiceUrl + '/gsv/' + searchType + '/' + searchId + '/'
      + offset + '/' + limit + '/' + searchText + conditions, { headers: headers }) as Observable<Array<GlobalSearchViews>>;
  }

  public getBiosimilarInfo(rcId: string): Observable<Object> {
      let headers = this.setHeaders();
      return this._http.get(this.config.ServiceUrl + '/biosimilar/' + rcId,
          { headers: headers });
  }
}
