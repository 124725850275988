import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AccountOutput } from 'src/app/shared/models/account-output.data.model';
import { EnvironmentData } from 'src/app/shared/models/environment.data.model';
import { AuthorizationService } from '../../../shared/services/authorization.service';
import { CommonService } from '../../../shared/services/common.service';
import { Logger } from '../../../shared/services/logger.service';

@Component({
  selector: 'layout-header',
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit {
  public accountDetails: AccountOutput;
  public userName: string = '';
  public orgName: string = JSON.parse(sessionStorage.getItem('accountDetails')).org_name;
  public config: EnvironmentData;
  public showUserSettingDiscount: boolean = false;
  public showAdminTools: boolean = false;
  public currentDate: Date = new Date();
  constructor(
    private authService: AuthorizationService,
    private commonService: CommonService,
    private logger: Logger,
    private router: Router
  ) { }

  public ngOnInit(): void {
    this.config = JSON.parse(sessionStorage.getItem('config'));
    this.accountDetails = JSON.parse(sessionStorage.getItem('accountDetails'));
    this.decideFeatureOnOff(this.accountDetails);
    if (this.accountDetails) {
      this.userName = this.accountDetails.contact_first;
    }
    this.showAdminTools = this.accountDetails.admin_tools_ind !== '0';
  }

  private decideFeatureOnOff(accountDetails: AccountOutput): void {
    accountDetails.features.split(',').forEach((value, key) => {
      if (value === 'UD') {
        this.showUserSettingDiscount = true;
      }
    });
  }

  public logOut(): void {
    localStorage.removeItem('key');
    let sessionId = sessionStorage.getItem('uid') ? sessionStorage.getItem('uid') : '';
    if (sessionId) {
      this.authService.deleteSession(sessionId).subscribe(res => {
        window.name = '';
        sessionStorage.removeItem('isValidUser');
        sessionStorage.removeItem('uid');
        sessionStorage.removeItem('accountDetails');
        this.router.navigate(['/logout']);
        // window.location.replace(this.config.LogOutPage);
      }, (err: Response) => {
        this.logger.error(err['error'].message);
      });
    } else {
      window.name = '';
      sessionStorage.removeItem('isValidUser');
      sessionStorage.removeItem('uid');
      sessionStorage.removeItem('accountDetails');
      this.router.navigate(['/logout']);
      // window.location.replace(this.config.LogOutPage);
    }
  }

  public uitracking(featureCode: string): void {

    var requestUITrack = {
      'account_id': this.accountDetails.account_id,
      'service_used': 'UITRACK',
      'search_criteria': featureCode,
    };

    this.commonService.uiTrakcing(requestUITrack).catch(err => {
      this.logger.error(err['error'].message);
    });
  }

  public trackLogout(): void {
    var requestUITrack = {
      'account_id': this.accountDetails.account_id,
      'service_used': 'UITRACK',
      'search_criteria': 'CTTERM',
    };

    this.commonService.uiTrakcing(requestUITrack).catch(err => {
      this.logger.error(err['error'].message);
    }).then(() => { this.logOut(); });

  }
}
