import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { RegistrationService } from 'src/app/shared/services/registration.service';

@Component({
  selector: 'app-free-trial',
  templateUrl: './free-trial.component.html',
  styleUrls: ['./free-trial.component.scss']
})
export class FreeTrialComponent implements OnInit {

  public error: boolean = false;
  public warn: boolean = false;
  public errMsg: string = '';
  public success: boolean = false;
  public successMsg: string = '';
  private pattern: string = "^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,9}$"

  FreeTrialForm = this.fb.group({
    companyName: [null, Validators.required],
    firstName: [null, Validators.required],
    lastName: [null, Validators.required],
    phone: [null, Validators.required],
    email: [null, Validators.required],
    department: [null, Validators.required],
    orgType: [null, Validators.required],
    title: [null, Validators.required],
    username: [null, Validators.required],
    password: [null, Validators.required]
  });

  MeetingForm = this.fb.group({
    intrestedIn: ['Demo'],
    name: [null, Validators.required],
    company: [null, Validators.required],
    email: [null, Validators.required],
    phone: [null, Validators.required],
    message: [null]
  });

  constructor(
    private fb: FormBuilder,
    public regService: RegistrationService,
    private router: Router
  ) { }

  ngOnInit() {
  }

  onFreeTrialFormSubmit() {
    const freeDomain = ['gmail.com', 'hotmail.com', 'yahoo.com', 'outlook.com', 'msn.com']
    this.error = false;
    this.success = false;
    let email: string = this.FreeTrialForm.controls.email.value;
    let emailDomain = email ? email.substring(email.indexOf('@') + 1, email.length) : '';
    if (this.FreeTrialForm.invalid || this.FreeTrialForm.value.orgType === 'null' ) {
      window.scrollTo(0, 0)
      this.error = true;
      this.errMsg = "Please fill in all the required fields";
      return;
    } 
    if (this.FreeTrialForm.controls.phone.value.length !== 10) {
      this.error = true;
      this.errMsg = "Phone number format is incorrect.";
      return;
    }
    if (email && !email.match(this.pattern)) {
      window.scrollTo(0, 0)
      this.error = true;
      this.errMsg = "Email format is incorrect";
      return
    }
    if (freeDomain.includes(emailDomain)) {
      window.scrollTo(0, 0)
      this.error = true;
      this.errMsg = "Sorry but free email domains such as Hotmail or Gmail are not allowed to automatically sign up for a free trial. Please contact us at: info@reimbursementcodes.com to setup your free trial with our Sales team.";
      return;
    }
    this.regService.domainCheck(emailDomain).subscribe(res => {
      if (res['success'] === true || res['success'] === 'true') {
        this.error = true;
        this.errMsg = "Based on your email address, your organization has an account with ReimbursementCodes.com or has completed a Free Trial previously. If you have a company affiliated access code, please select the Current Customer Section of this form to continue. If you don't know your access code, please contact Client Relations at: Info@ReimbursementCodes.com"
      } else {
        this.success = true;
        let emailJsonData = {
          'email': email,
          'site': "0",
          "category": null
        }

        this.regService.validateEmail(emailJsonData).subscribe(response => {
          let status = response['status'];

          if (status === 0 || status === '0') {
            this.error = false;
            let userJsonData = {
              'username': this.FreeTrialForm.controls.username.value,
              'site': "0",
              "category": null
            }

            this.regService.validateUsername(userJsonData).subscribe(resp => {
              let status = resp['status'];
              if (status === 0 || status === '0') {
                this.error = false;

                let jsonData = {
                  'contact_first': this.FreeTrialForm.controls.firstName.value,
                  'contact_last': this.FreeTrialForm.controls.lastName.value,
                  'phone': this.FreeTrialForm.controls.phone.value,
                  'email': this.FreeTrialForm.controls.email.value,
                  'department': this.FreeTrialForm.controls.department.value,
                  'title': this.FreeTrialForm.controls.title.value,
                  'specialty': this.FreeTrialForm.controls.orgType.value,
                  'username': this.FreeTrialForm.controls.username.value,
                  'password': this.FreeTrialForm.controls.password.value,
                  'orgid': '1',
                  'address_1': "",
                  'address_2': "",
                  'fax': "",
                  'city': "",
                  'state': "",
                  'zip': "",
                  'hear_about_us': "",
                  'npi': "",
                  'other': "VRFY",
                }

                this.regService.addAccount(jsonData).subscribe(res1 => {
                  if (res1[0]['message'] === 'Add account successful') {
                    this.success = true;
                    this.successMsg = "Account added successfully"
                    let verificationData = {
                      'first_name': this.FreeTrialForm.controls.firstName.value,
                      'last_name': this.FreeTrialForm.controls.lastName.value,
                      'email': email,
                      'user_name': this.FreeTrialForm.controls.username.value,
                      'password': this.FreeTrialForm.controls.password.value,
                      'orgId': '1',
                      'category': "",
                      'verification_aa_id': res1[0]['verfication_aa_id'],
                      'account_id': res1[0]['account_id'],
                      'site': '0'
                    }

                    this.regService.varificationEmail(verificationData).subscribe(vryres => {
                      let vryStatus = vryres['status']
                      if (vryStatus === 0 || vryStatus === '0') {
                        this.success = true;
                        this.successMsg = 'An email has been sent to you email id with the username and password.'
                      } else if (vryStatus === '1' || vryStatus === 1) {
                        this.error = true;
                        this.errMsg = 'Email not sent';
                      } else {
                        this.error = true;
                        this.errMsg = 'Error in sending email';
                      }
                    }, () => {
                      this.error = true;
                      this.errMsg = 'Error';
                    });

                    window.scrollTo(0, 0);
                    alert('Your account has been registered successfully.');
                    this.router.navigate(['/about']);
                  }
                }, () => {
                  this.error = true;
                  this.errMsg = 'Error';
                });
              } else if (status === '1' || status === 1) {
                this.error = true;
                this.errMsg = 'Username already in use';
              } else if (status === '2' || status === 2) {
                this.error = true;
                this.errMsg = 'Username format is invalid';
              } else {
                this.error = true;
                this.errMsg = 'Error';
              }
            }, () => {
              this.error = true;
              this.errMsg = 'Error';
            });
          } else if (status === '1' || status === 1) {
            this.error = true;
            this.errMsg = 'Email domain is already registered';
          } else if (status === '2' || status === 2) {
            this.error = true;
            this.errMsg = 'Email format is invalid';
          } else if (status === '3' || status === 3) {
            this.error = true;
            this.errMsg = 'Email already registered for the product';
          } else if (status === '4' || status === 4) {
            this.error = true;
            this.errMsg = "Based on your email address, your organization has an account with ReimbursementCodes.com or has completed a Free Trial previously. If you have a company affiliated access code, please select the Current Customer Section of this form to continue. If you don't know your access code, please contact Client Relations at: Info@ReimbursementCodes.com"
          } else {
            this.error = true;
            this.errMsg = 'Error';
          }
        }, () => {
          this.error = true;
          this.errMsg = 'Error';
        });
      }
    })
  }

  onMeetingFormSubmit() {
    this.errMsg = '';
    this.error = false;
    this.success = false;
    this.warn = false
    let email: string = this.MeetingForm.controls.email.value;
    if (this.MeetingForm.invalid) {
      this.warn = true;
      this.errMsg = "One or more fields have an error. Please check and try again.";
    } else if (email && !email.match(this.pattern)) {
      this.warn = true;
      this.errMsg = "Email format is incorrect.";
    } else if (this.MeetingForm.controls.phone.value.length !== 10) {
      this.warn = true;
      this.errMsg = "Phone number format is incorrect.";
    } else {
      let feedbackJsonData = {
        "firstName": this.MeetingForm.controls.name.value,
        "lastName": "",
        "email": email,
        "phoneNumber": this.MeetingForm.controls.phone.value,
        "help_you": this.MeetingForm.controls.intrestedIn.value,
        "comment": this.MeetingForm.controls.message.value,
        "companyName": this.MeetingForm.controls.company.value,
        "accountID": "",
        "address": "",
        "city": "",
        "state": "",
        "zip": "",
        "fax": ""
      }

      this.regService.sendFeedback(feedbackJsonData).subscribe(response => {
        if (response["status"] == "0") {
          this.warn = true;
          this.errMsg = "Your message has been sent. We will get back to you with a response shortly.";
          this.MeetingForm.reset({intrestedIn: 'Demo'});
        } else {
          this.error = true;
          this.errMsg = "There was an error trying to send your message. Please try again later.";
        }

      }, () => {
        this.error = true;
        this.errMsg = "There was an error trying to send your message. Please try again later.";
      });
    }
  }

  changeTab() {
    this.error = false;
    this.success = false;
    this.warn = false;
  }
}
